@import "base.less";

.inputPassword {
	border: 1px solid #C0C0C0;
	box-sizing: border-box;
	box-shadow: none;
	border-radius: 100px;
	font-size: 1em;
	line-height: 1.17em;
	padding: 1em 1.3em 0.78em 1.3em;
	display: flex;
	background-color: white;

	&:hover {
		border: 1px solid #878787;
	}

	&.focus {
		border: 1px solid #878787;
		outline: none;
	}

	&.error {
		border: 1px solid #FF0000;
	}

	input:not(.vs__search) {
		border: 0;
		padding: 0;
		box-shadow: inset 0 0 0;
		border-radius: 0;
		flex-grow: 1;

		&:not([disabled]):hover {
			border: 0;
		}
	}

	.psButton {
		margin: auto;
		margin-left: @EM10;
		cursor: pointer;
	}
}

.buttonRed {
	background: #DC4228;
	border-radius: @EM100;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	cursor: pointer;
	padding: 13px @EM25;
	box-sizing: border-box;
	user-select : none;

	&:hover {
		background: #E75A42;
	}

	&.pushed {
		background: #BE280F;
	}

	&.disable {
		background: #A9A9A9;
		box-shadow: none;
		cursor: default;
		&:hover {
			color: #FFFFFF;
		}
	}
}

.buttonWhite {
	background: #FFFFFF;
	border: @EM2 solid #DC4228;
	box-sizing: border-box;
	border-radius: @EM100;
	cursor: pointer;
	padding: 1em @EM25;
	user-select : none;

	font-weight: bold;
	text-align: center;
	letter-spacing: 0.02em;
	color: #DC4228;

	&:hover {
		background: #FFEAE7;
	}

	&.pushed {
		background: #DC4228;
		color: #FFFFFF;
	}

	&.disable {
		background: #A9A9A9;
		box-shadow: none;
		color: #FFFFFF;
		cursor: default;
		&:hover {
			color: #FFFFFF;
		}
	}
}

.buttonGray {
	background: #FFFFFF;
	border: 2px solid #BBBBBB;
	box-sizing: border-box;
	border-radius: @EM100;
	cursor: pointer;
	user-select : none;
	padding: 12px @EM25;
	font-weight: 600;
	text-align: center;
	color: #868686;

	&:hover {
		border: 2px solid #828282;
		color: #626262;
	}

	&.pushed {
		background: #9A9A9A;
		border: 2px solid #9A9A9A;
		border-radius: 40px;
		color: #FFFFFF;
	}

	&.disable {
		background: #A9A9A9;
		box-shadow: none;
		color: #FFFFFF;
		cursor: default;
		&:hover {
			border: 2px solid #BBBBBB;
			color: #FFFFFF;
		}
	}
}

.buttonNone {
	font-size: 0.9em;
	line-height: 1.17em;
	color: #DC4228;
	cursor: pointer;
	text-align: center;
	padding: 6px 12px;
	user-select : none;

	&:hover {
		color: #AE2B14;
	}

	&.pushed {
		background: rgba(220, 66, 40, 0.25);
		border-radius: 15.5px;
		color: #AE2B14;
	}
}

span.href {
	color: #DC4228;
	cursor: pointer;
	&:hover {
		color: #AE2B14;
		text-decoration: underline;
	}
}

.horFlex {
	min-width: 1100px;
	width : 100%;
	height: 100%;
	position: relative;

	display: flex;

	.vertFlex {
		width : 477px;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-shrink:0
	}

	.regFiled {
		margin-left: 36px;

		.titleProd {
			font-weight: 500;
			font-size: 28px;
			line-height: 1.3em;
			color: black;
			letter-spacing: 0.01em;
			display: flex;
			padding: 60px 54px 36px 0;

			.logo {
				flex-shrink: 0;
				background: data-uri(@logo);
				width: 49px;
				height: 56px;
				margin-right: 16px;
			}
		}

		.aboutProd {
			font-family: Raleway;
			font-feature-settings: 'pnum' on, 'lnum' on;
			font-weight: 700;
			margin-bottom: 16px;
			width : 414px;
			font-size: 36px;
			line-height: 1.3;

			.redcolortext {
				color: #DC4228;
			}
		}

		.forProd {
			color: #4F4F4F;
			font-weight: 500;
			margin-bottom: 64px;
			font-size: 20px;
			line-height: 23.48px;
			width : 300px;
		}

		.bstart {
			width: 275px;
		}


	}


	@bk-path_1100: "imgs/bk_1100.png";

	.bkimageF {
		position: absolute;
		bottom: 21px;
		width: image-width(@bk-path_1100);
		height: image-height(@bk-path_1100);
		left: 192px;

		.bkimage {
			margin: auto;
			width: image-width(@bk-path_1100);
			height: image-height(@bk-path_1100);
			background-image: url(@bk-path_1100);
		}
	}

    .btn_telegram {
        position: absolute;
        box-sizing: border-box;
        bottom: 56px;
        left: 36px;
        width: 36px;
        height: 36px;
        background-image: url("imgs/telegram-atlas.svg");
        background-position-x: 0px;
        cursor: pointer;

        &:hover {
            background-position-x: -36px;
        }
    }

    .btn_vk {
        position: absolute;
        box-sizing: border-box;
        bottom: 56px;
        left: 76px;
        width: 36px;
        height: 36px;
        background-image: url("imgs/vk-atlas.svg");
        background-position-x: 0px;
        cursor: pointer;

        &:hover {
            background-position-x: -36px;
        }
    }

    .btn_support {
        position: absolute;
        box-sizing: border-box;
        bottom: 56px;
        left: 115px;
        width: auto;
        height: 36px;
        font-family: Raleway;
	    font-feature-settings: 'pnum' on, 'lnum' on;
        font-weight: 500;
        font-size: 16px;
        border-radius: 18px;
        padding: 7px;
        background-color: transparent;
        user-select: none;
        color: #DC4228;
        cursor: pointer;

        &:hover {
            color: #AE2B14;
        }

        &:active {
            background-color: rgba(220, 66, 40, 0.25);
        }
    }

	.frame_reclam {
		background: white;
		border: 0;
		border-style: none;
		margin: 0;
		width: 100%;
		height: 100%;
	}

	.buttonsLR {
		display: flex;
		position: absolute;
		bottom: 36px;
		/* размер экрана - отступ слева и справа - размер плашки с отсутупом посрединеё = делим пополам и смещаем к началу белой области*/
		left: calc((100% - (477px + 0px) - 90px - 12px) / 2 + 477px);

		.bleft {
			width: 52px;
			height: 51px;
			background-image: url("imgs/left.svg");
			cursor: pointer;
			margin-right: 6px;

			&:hover {
				background-image: url("imgs/left_h.svg");
				background-position-x: 3px;
				background-repeat: no-repeat;
			}
		}
		.bright {
			width: 52px;
			height: 51px;
			background-image: url("imgs/right.svg");
			cursor: pointer;
			&:hover {
				background-image: url("imgs/right_h.svg");
				background-position-x: 3px;
				background-repeat: no-repeat;
			}
		}
	}
}

.viewmail{
	font-weight: bold;
	letter-spacing: 0.02em;
	color: #DC4228;
}

p.viewmail {
	margin-top: 0
}

.profileBack {
	width: 860px;
	overflow: hidden;
	padding: 24px 18px 24px 18px;
	box-sizing: border-box;
	background-color: #f5efe6;

	.profileUp {
		display: flex;

		.profileLeft {
			width: 50%;
			display: flex;
			flex-direction: column;
			margin-right: 24px;
		}
		.profileRight {
			width: 50%;
			display: flex;
			flex-direction: column;
			margin-left: 0;
		}

		.titleProd {
			margin-left: 10px;
		}

	}
	.btn {
		width: 160px;
		display: inline-block;
		height: 1.17em;
		box-sizing: content-box;
		flex-shrink: 0;
	}

	.dvError {
		width: 100%;
		font-size: 0.78em;
		line-height: 1.35em;
		min-height: @EM1;
		margin-left: 24px;
		align-self: center;

		&.viewError:before {
			display: inline-block;
			content:' ';
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMS41MjI3IDIuNjY2NjdMMjkuMzMzMyAxMC40NzczVjIxLjUyMjdMMjEuNTIyNyAyOS4zMzMzSDEwLjQ3NzNMMi42NjY2NyAyMS41MjI3VjEwLjQ3NzNMMTAuNDc3MyAyLjY2NjY3SDIxLjUyMjdaTTIyLjYyOCAwSDkuMzcyTDAgOS4zNzJWMjIuNjI2N0w5LjM3MiAzMkgyMi42MjY3TDMyIDIyLjYyOFY5LjM3MkwyMi42MjggMFpNMTEuMzE4NyAyMi42NTZMMTYuMDA5MyAxNy44OTg3TDIwLjc0OCAyMi41OTMzTDIyLjY1NiAyMC42ODY3TDE3LjkwMTMgMTUuOTg5M0wyMi42MTQ3IDExLjIzMkwyMC43MDY3IDkuMzIyNjdMMTUuOTg4IDE0LjFMMTEuMjEzMyA5LjQ5MDY3TDkuMzIyNjcgMTEuMzgxM0wxNC4xMDI3IDE2LjAxMkw5LjQyNjY3IDIwLjc2NjdMMTEuMzE4NyAyMi42NTZaIiBmaWxsPSJibGFjayIvPg0KPC9zdmc+DQo=");
			background-size: contain;
			width: 1.28em;
			height: 1.17em;
			margin-right: 0.4em;
			vertical-align: text-bottom;
		}

		&.viewSuccess:before {
			display: inline-block;
			content: ' ';
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyNyAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMiAxMy41ODYyTDExLjMwMjIgMjNMMjUgMiIgc3Ryb2tlPSIjREM0MjI4IiBzdHJva2Utd2lkdGg9IjQiLz4NCjwvc3ZnPg0K');
			width: 1.28em;
			height: 1.17em;
			margin-right: 0.4em;
			vertical-align: bottom;
		}
	}

	.inputProfile {
		width : 400px;
	}

	.ddProfile {
		width: 200px;
		box-shadow: none;
	}
}

.p-dropdown-panel {
	margin-top: 8px !important;
	border-radius: 24px;
	font-family: Raleway;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 500;
	font-size: 18px;

	.p-dropdown-items-wrapper {
		border-radius: 24px;

		.p-dropdown-items {
			padding: 0;

			.p-dropdown-item {
				&.p-highlight {
					background: #DC4228;
				}
				&:not(.p-highlight):not(.p-disabled).p-focus {
					background: #EFCFC6;
				}
			}

		}

	}
}

.p-dropdown:not(.p-disabled).p-focus {
	box-shadow : none;
}

.questBack {
	width: 100%;
	height: 615px;
	overflow: hidden;
	padding: 15px 0 0px;
	box-sizing: border-box;
	background-color: #f5efe6;
	display: flex;
	flex-direction: column;

	.titleProd{
		color: #dc4228;
		font-weight: bold;
	}

	.blocktitle {
		color: #dc4228;
	}

	.quests {
		overflow: auto;
		flex-grow: 1;
		padding-right: 50px;
		padding-left: 60px;

		.titleProd {
		}

		.blocktitle {
			margin-bottom: 10px;
		}

		.titleQuest {
			color: black;
			font-weight: normal;

			.titleQuestText {
				color: black;
				font-weight: bold;
			}

			.titleQuestDesc {
				font-size: 0.8em;
			}
		}
		.qRadio {
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			margin-left: 25px;

			label {
				margin-bottom: 10px;
			}
		}

		.qCheck {
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
			margin-left: 25px;

			label {
				margin-bottom: 10px;
			}
		}
	}

	.bpanel {
		border-top: 1px solid #c0c0c0;
		display: flex;
		padding-left: 60px;
	}
	.btn {
		width: 160px;
		display: inline-block;

		&.btnS {
			margin-left: auto;
			margin-right: 20px;
			align-self: center;
		}
	}

	.allquests {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 50px;

		.titleProd {
			margin: auto;
		}
	}
	.aboutafter {
		font-style: italic;
		color: black;
		font-weight: normal;
	}
}

.logindlg {
	&.p-dialog {
		.p-dialog-header {
			border: 0;
			padding-top: 8px;
			padding-bottom: 8px;
			min-height: 48px;

			.p-dialog-header-close {
				&:hover{
					svg {
						color: #BE280F;
					}

				}
			}
		}
		.p-dialog-content {
			padding: 48px;
			padding-top: 0;
			font-family: Raleway;
			font-feature-settings: 'pnum' on, 'lnum' on;

			.simpleText {
				font-size: 14px;
				font-weight: 500;
				line-height: 18px;
				text-align: center;
				margin-bottom: 32px;
			}
			.loginPanel {

				margin-left: auto;
				margin-right: auto;

				background: #FFFFFF;
				border-radius: 10px;

				.buttonNone {
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
				}

				.lpTitle {
					font-family: Raleway;
					font-feature-settings: 'pnum' on, 'lnum' on;
					font-weight: 700;
					font-size: 24px;
					line-height: 28.18px;
					text-align: center;
					color: #000000;
					margin-bottom: @EM10;
				}

				.lp1 {
					text-align: center;
					div {
						display: inline-block;

						&.lp1_text {
							font-weight: 500;
							font-size: 16px;
							line-height: 18px;
							color: #868686;
							margin-right: 5px;
						}
					}
				}

				.desc {
					font-size: 14px;
					line-height: 19px;
					font-weight: 500;
					margin-left: @EM75;
					margin-right: @EM75;
					width: calc(100% - @EM150);
				}
				.descp {
					font-size: 0.78em;
					line-height: 1.35em;
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);
				}
				input {
					margin-top: @EM10;
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);
				}
				.p-dropdown {
					margin-top: @EM10;
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);
					box-shadow: none;
					.p-inputtext {
						&.p-placeholder {
							color: #868686;
							font-size: 16px;
						}
					}
				}

				.inputPassword {
					margin-top: @EM10;
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);

					input {
						margin : 0;
						width: 100%;
					}
				}

				.selch {
					margin-top: @EM10;
					margin-left: @EM50;
					margin-right: @EM50;
					display:flex;
					justify-content: center;
					align-items: center
				}

/*				.binput {
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);
				}*/

				.bps {
					margin-left: @EM50;
					margin-right: @EM50;
					//width: calc(100% - @EM100);
				}

				.messageTitle {
					text-align: center;
					font-weight: bold;
					font-size: 1.33em;
					line-height: 1.17em;
					color: #DC4228;

					&.messageRight {
						&:before {
							display: inline-block;
							content:' ';
							background-position: 0 0;
							background-repeat: no-repeat;
							background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyNyAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMiAxMy41ODYyTDExLjMwMjIgMjNMMjUgMiIgc3Ryb2tlPSIjREM0MjI4IiBzdHJva2Utd2lkdGg9IjQiLz4NCjwvc3ZnPg0K');
							width: 1.28em;
							height: 1.17em;
							margin-right: 0.4em;
							vertical-align: bottom;
						}
					}
					&.messageFail {
						color: black;

						&:before {
							display: inline-block;
							content:' ';
							background-position: 0 0;
							background-repeat: no-repeat;
							background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMS41MjI3IDIuNjY2NjdMMjkuMzMzMyAxMC40NzczVjIxLjUyMjdMMjEuNTIyNyAyOS4zMzMzSDEwLjQ3NzNMMi42NjY2NyAyMS41MjI3VjEwLjQ3NzNMMTAuNDc3MyAyLjY2NjY3SDIxLjUyMjdaTTIyLjYyOCAwSDkuMzcyTDAgOS4zNzJWMjIuNjI2N0w5LjM3MiAzMkgyMi42MjY3TDMyIDIyLjYyOFY5LjM3MkwyMi42MjggMFpNMTEuMzE4NyAyMi42NTZMMTYuMDA5MyAxNy44OTg3TDIwLjc0OCAyMi41OTMzTDIyLjY1NiAyMC42ODY3TDE3LjkwMTMgMTUuOTg5M0wyMi42MTQ3IDExLjIzMkwyMC43MDY3IDkuMzIyNjdMMTUuOTg4IDE0LjFMMTEuMjEzMyA5LjQ5MDY3TDkuMzIyNjcgMTEuMzgxM0wxNC4xMDI3IDE2LjAxMkw5LjQyNjY3IDIwLjc2NjdMMTEuMzE4NyAyMi42NTZaIiBmaWxsPSJibGFjayIvPg0KPC9zdmc+DQo=");
							background-size: contain;
							width: 1.28em;
							height: 1.17em;
							margin-right: 0.4em;
							vertical-align: bottom;
						}
					}
				}

				.messageText {
					font-size: 20px;
					line-height: 1.33em;

					&.messageTextRed {
						color : #DC4228;
						font-weight: bold;
					}
				}

				.bigText {
					font-size: 20px;
					line-height: 1.33em;
//					margin-left: @EM50;
//					margin-right: @EM50;
//					width: calc(100% - @EM100);
					max-height: 480px;
					overflow: auto;
				}

				.dvError {
					margin-top: @EM10;
					margin-left: @EM50;
					margin-right: @EM50;
					width: calc(100% - @EM100);
					text-align: center;
					font-size: 0.78em;
					line-height: 1.35em;
					min-height: @EM1;

					&.viewError:before {
						display: inline-block;
						content:' ';
						background-position: 0 0;
						background-repeat: no-repeat;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMS41MjI3IDIuNjY2NjdMMjkuMzMzMyAxMC40NzczVjIxLjUyMjdMMjEuNTIyNyAyOS4zMzMzSDEwLjQ3NzNMMi42NjY2NyAyMS41MjI3VjEwLjQ3NzNMMTAuNDc3MyAyLjY2NjY3SDIxLjUyMjdaTTIyLjYyOCAwSDkuMzcyTDAgOS4zNzJWMjIuNjI2N0w5LjM3MiAzMkgyMi42MjY3TDMyIDIyLjYyOFY5LjM3MkwyMi42MjggMFpNMTEuMzE4NyAyMi42NTZMMTYuMDA5MyAxNy44OTg3TDIwLjc0OCAyMi41OTMzTDIyLjY1NiAyMC42ODY3TDE3LjkwMTMgMTUuOTg5M0wyMi42MTQ3IDExLjIzMkwyMC43MDY3IDkuMzIyNjdMMTUuOTg4IDE0LjFMMTEuMjEzMyA5LjQ5MDY3TDkuMzIyNjcgMTEuMzgxM0wxNC4xMDI3IDE2LjAxMkw5LjQyNjY3IDIwLjc2NjdMMTEuMzE4NyAyMi42NTZaIiBmaWxsPSJibGFjayIvPg0KPC9zdmc+DQo=");
						background-size: contain;
						width: 1.28em;
						height: 1.17em;
						margin-right: 0.4em;
						vertical-align: text-bottom;
					}

				}
			}

		}
	}
}

.supportdlg {

    font-family: 'Raleway', sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on;
    -webkit-font-smoothing: antialiased;

    &.p-dialog {
        .p-dialog-header {
            border: 0px;
            padding-top: 8px;
            padding-bottom: 8px;
            min-height: 48px;

            .p-dialog-header-close {
                &:hover{
                    svg {
                        color: #BE280F;
                    }

                }
            }
        }        
        
        .p-dialog-content {
            padding: 0px 36px 36px 36px;
        }
    }

    .header {
        font-weight: 700;
        font-size: 24px;
        line-height: 28.18px;
        text-align: center;
        color: #000000;
        margin-bottom: @EM10;
    }

    .simple {
        font-weight: 500;
        font-size: 18px;
    }
}

a {
	color: #dc4228;
	text-decoration: underline;
	cursor: pointer;
}

@import "media.less";
